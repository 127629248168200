/* Service Card css */
.services-Container{
    background: rgb(255, 255, 255);
    /* background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(211,161,98,1) 10%, rgba(0,0,0,1) 10%); */

    color: rgb(0, 0, 0) ;
    /* display: grid; */

    border: 0px;
    border-style: solid;
    border-radius: 5px;
}

.Service-Card-Container{
    justify-content: center;
 align-items: center;
}


.Service-Button-Container{
    display: flex;
    justify-content: center;
}
.Service-Card-Title{
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    padding-bottom: 30px;
    padding-top: 20px;
}
.Service-Card-Discription{
    text-align: center;
    font-size: 20px;
}
.Service-Card-Price{
    text-align: center;
    font-weight: bold;
    font-style: italic;
    padding-bottom: 10px;
}

/* Prev button css */
.Service-Prev-Button{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);

    font-size: 20px;
    padding: 10px 60px;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
}
.Service-Prev-Button:hover{
    opacity: 50%;
}

/* Next Button css */

.Service-Next-Button{
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);

    font-size: 20px;
    padding: 10px 60px;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
}


@media screen and (max-width: 800px) {
    
}