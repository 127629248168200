.navbar{
    background-color: transparent;
    color: black;
    height: 0px;
    position: sticky;
    top: 0;
    z-index: 999;

}
.navbar-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.navbar-logo-image{
    width: 80px;
    height: 80px;
}
.nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
    padding-right: 50px;
}

.menu-icon {
    display: block;
    position: absolute;
    top: 25px;
    right: 20px;
    padding-left: 10px;
    font-size: 1.8rem;
    cursor: pointer; 
}

  
.nav-item {
    height: 30px;
    border-bottom: 2px solid transparent;
    padding-right: 10px;
    padding-left: 10px;
}

.nav-item:hover {
    border-bottom: 2px solid #000000;
    opacity: 0.8;
}
  
.nav-links {
  color: #ffbb35;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover{
  opacity: 0.8;
}


@media screen and (max-width: 500px) {
    .NavbarItems {
        position: relative;
      }
    
      .menu-icon{
        color: #000000;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height:20vh;
        position: absolute;
        top: 50px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
      }
    .nav-menu.active {
        background: transparent;
        left: 0;
        opacity: 1;
        transition: all 0.6s ease;
        z-index: 0.5;

    }
      .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
      }
    
      .nav-links:hover {
        color: #ffbb35;
        transform: scale(1.2);
        transition: all 0.3s ease;
      }
    
      .nav-item:hover {
        border: none;
      }
    
      .nav-item {
        width: 100%;
        padding-bottom: 60px;
      }
}