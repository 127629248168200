.slider{
    /* position: relative; */
    /* height: 100rem; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(255, 255, 255);
    /* background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(211,161,98,1) 50%, rgba(0,0,0,1) 100%); */

}

.carousel-wrapper{
    width: 100px;
    height: 100px;
}

.imgs{
display: flex;
   align-content: center;
   justify-content: center;
   width: 400px; 

}
.img{
    height: 400px;


}


.slider-images{
    /* width: 1000px; */
    height: 600px;
    border-radius: 10px;
    
}
.right-arrow{
    /* position: absolute; */
    /* top: 50%; */
    /* right: 32px; */
    font-size: 3rem;
    color: rgb(0, 0, 0);
    z-index: 10;
    user-select: none;
}
.left-arrow{
    /* position: absolute; */
    /* top: 50%; */
    /* left: 32px; */
    font-size: 3rem;
    color: rgb(0, 0, 0);
    z-index: 10;
    user-select: none;
}
.slid {
opacity: 0;
transition-duration: 1s ease;

}
.slide.active{
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}

@media screen and (max-width: 900px) {
    .slider{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(255, 255, 255);
        /* background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(211,161,98,1) 50%, rgba(0,0,0,1) 100%); */
    }

    .right-arrow{
        position: absolute;
        top: 50%;
        right: 32px;
        font-size: 3rem;
        color: rgb(0, 0, 0);
        z-index: 10;
        user-select: none;
    }
    .left-arrow{
        position: absolute;
        top: 50%;
        left: 32px;
        font-size: 3rem;
        color: rgb(0, 0, 0);
        z-index: 10;
        user-select: none;
    }
    .gallery-img{
        /* width: 100%; */
        /* height: max-content; */
        min-width: 300px;
        min-height: 300px;
        max-width: 100%;   
        padding-bottom: 40px;
    }
    
  
}